
export default class AuthService {
    // Inicializando variáveis ​​importantes
    constructor(domain) {
    
        this.domain = domain || process.env.REACT_APP_API_URL

        this.fetch = this.fetch.bind(this) // React binding stuff
        this.login = this.login.bind(this)
    }


    login(hash) {

    }

    loggedIn() {
        // Verifica se há uma accessKey salvo e ainda é válido
        return localStorage.getItem("_accessKey") ? true : false;
    }

    logout() {
        console.log("sair");
        localStorage.removeItem('_accessKey');
        window.location.replace("/")
    }

    getAccessKey() {
        return localStorage.getItem("_accessKey")
    }

    setAccessKey(accessKey) {
        localStorage.setItem("_accessKey", JSON.stringify(accessKey))
    }

    fetch(url, options) {

        if (!options) {
            options = {}
        }
        options.headers = {
            "Authorization": "Bearer ",
            "Accept": "application/json",
            "Content-Type": "application/json; charset=utf-8"
        }
        return fetch(url, options)
            .then(this._checkStatus)
            .then(response => response.json())
    }

    get(url) {
        return fetch(this.domain + url, {
            method: 'GET',
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json; charset=utf-8"
            }
        })
            .then(this._checkStatus)
            .then(response => response.json())
    }

    post(url, obj) {
        return fetch(this.domain + url, {
            method: 'POST',
            body: JSON.stringify(obj),
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json; charset=utf-8"
            }
        })
            .then(this._checkStatus)
            .then(response => response.json())
    }

    put(url, obj) {
        return fetch(this.domain + url, {
            method: 'PUT',
            body: JSON.stringify(obj),
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json; charset=utf-8"
            }
        })
            .then(this._checkStatus)
            .then(response => response.json())
    }

    delete(url, obj) {
        return fetch(this.domain + url, {
            method: 'DELETE',
            body: JSON.stringify(obj),
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json; charset=utf-8"
            }
        })
            .then(this._checkStatus)
            .then(response => response.json())
    }

    _checkStatus(response) {
        // gera um erro caso o status da resposta não seja um sucesso
        if (response.status >= 200 && response.status < 300) { // O status de sucesso situa-se entre 200 e 300
            return response
        } else {
            console.log('Falha ao logar')
            var error = new Error(response.statusText)
            error.response = response
            throw error
        }
    }
}