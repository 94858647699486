import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { Link as RouterLink } from 'react-router-dom';
import SettingsIcon from '@material-ui/icons/Settings';
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import AuthService from '../AuthService';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import PrintIcon from '@material-ui/icons/Print';
import InfoIcon from '@material-ui/icons/Info';
 

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));

export default function HeaderAppBar() {
  const classes = useStyles();
  const appVersion = require('../../package.json').version

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const logout = () =>{
    let service = new AuthService()
    service.logout()
    handleClose()
  }
  
  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Toolbar>
          <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu" onClick={handleClick}>
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            Monitor de impressão
          </Typography>
          <Typography >{appVersion}</Typography>
        </Toolbar>
      </AppBar>

      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <RouterLink to="/home" style={{ textDecoration: 'none'  }} color="primary" >
          <MenuItem onClick={handleClose} >
            <NotificationsNoneIcon fontSize="small" color="primary" /> 
            <Typography color="primary">Monitor de impressão (tela principal)</Typography> 
          </MenuItem>
        </RouterLink>

        <RouterLink to="/configurarImpressoras" style={{ textDecoration: 'none'}}>
          <MenuItem onClick={handleClose}>
          <PrintIcon fontSize="small" color="primary"/>
            <Typography color="primary">Configurar impressoras</Typography> 
          </MenuItem>
        </RouterLink>

        <RouterLink to="/configuracoes" style={{ textDecoration: 'none'}}>
          <MenuItem onClick={handleClose}>
          <SettingsIcon fontSize="small" color="primary" />
            <Typography color="primary">Outras configurações</Typography>  
          </MenuItem>
        </RouterLink>

        <RouterLink to="/sobre" style={{ textDecoration: 'none'}}>
        <MenuItem onClick={handleClose}>
          <InfoIcon fontSize="small" color="primary" />
          <Typography color="primary">Sobre</Typography>  
        </MenuItem>
      </RouterLink>
      
        <RouterLink to="/" style={{ textDecoration: 'none'  }}>
          <MenuItem onClick={logout}><ExitToAppIcon fontSize="small"  color="primary" /> <Typography color="primary">Sair</Typography>  </MenuItem>
        </RouterLink>
      </Menu>
    </div>
  );
}