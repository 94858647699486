import { getGeneralSettings } from './Config';

require('string-format-js')

export default class Report {

    static buildReport(impressao) {

        var printLocations = getGeneralSettings().printLocations;
        
        let impressoraIndex = printLocations.findIndex(el => el.localObj.chave === impressao.localImpressao);
        let impressora = printLocations[impressoraIndex];
        
        console.log(impressora);

        if(!impressora){
            console.error("Impressora não configurada")
            return;
        }

        let string = "";
        impressao.conteudo.linhas.map(linha => {

            if (linha.texto === undefined){
                return null
            }

            let qtdeColunas = parseInt( impressora.QuantityColumns );
            let proporcaoLinha = linha.texto.length / qtdeColunas;

            if (proporcaoLinha > 1) {
               
                let roundTop = Math.ceil(proporcaoLinha);
                string += Report.stL(linha.texto, qtdeColunas * roundTop);
                
              
            } else {
               
                string += Report.criaLinha(linha, qtdeColunas);

            }
            return null
        })

        return string;
    }

    static criaLinha(linha, quantidadeColunas){

        switch (linha.alinhamento) {
            case "C":
                return this.stCenter(linha.texto, quantidadeColunas);
            
            case "E":
                return this.stL(linha.texto, quantidadeColunas);
               
            case "D":
                return this.stR(linha.texto, quantidadeColunas);
            
            case "P":
                return this.fill(linha.texto, quantidadeColunas);

            default:
                return null;
        }
    }

    static stR(obj, cols) {
        let key = `%${cols}s`
        return obj != null ? (key.format(obj)) : key.format('');
    }

    static stL(obj, cols) {
        let key = `%${-cols}s`
        return obj != null ? (key.format(obj)) : key.format('');
    }

    static stCenter(obj, cols) {
        let midle = Math.round((cols - obj.length) / 2)
        let value = Report.stR(Report.stL(obj, midle + obj.length), cols)
        return value;
    }

    static toDT(date, cols) {
        if (date == null)
            return Report.stR(date);

        let dt = new Date(date)
        return Report.stR((dt.toLocaleDateString() + ' ' + dt.toLocaleTimeString()), cols)
    }

    static fill(string, cols) {
        return Array(cols).fill(string).join('')
    }
    timeFormat(time) {
        let date = new Date(time)

        return date.toLocaleString();
    }

}