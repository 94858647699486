import React, { useEffect } from 'react';
import { io } from 'socket.io-client';
import AuthService from '../AuthService';
import { getGeneralSettings } from '../util/Config';
export default function SocketIO({addEvents, url}) {

    const service = new AuthService()
    const accessKey = service.getAccessKey()

    useEffect(() => {
        let extraRooms = "&rooms="
        if(!!getGeneralSettings().general.empresasAdicionadas){
            let isFirst = true;
            getGeneralSettings().general.empresasAdicionadas.forEach((empresa) => {
                if (isFirst) {
                    extraRooms += empresa.token;
                    isFirst = false;
                } else {
                    extraRooms += ',' + empresa.token;
                }
            });
        }

        if(extraRooms.length < 8){
            extraRooms = ""
        }

        console.log('Iniciando websocket', url); 
        const socket = io(url, {
            transports: ['websocket'],
            query: `uuid=${accessKey}` + extraRooms
        });

        console.log('Socket instanciado', socket); 

        socket.on("connect", () => {
            console.log('connect SocketIO', socket.id); 
        });
    
        socket.on("disconnect", () => {
            console.log('disconnect SocketIO', socket.id); 
        });
    
        addEvents(socket)
        
        return () => { if (socket) {  socket.disconnect() } }
        // eslint-disable-next-line
    }, [])

    return (
        <div>
            
        </div>
    );
}
