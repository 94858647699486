import { Table, TableBody, TableRow, Paper, TableCell, TableHead, Typography } from "@material-ui/core"
import React from "react"
import { useSelector } from "react-redux"
import EmptyRow from "../emptyRow"

export default function PedidosJaImpresso() {

    const impressoes = useSelector(state => state.impressoes)

    const impressoesFiltradas = impressoes.length > 0 && impressoes.filter(e => e.impressao.impresso)

    return (
        <>
            <Paper >
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>

                            <TableCell component="th" align="center">Descrição</TableCell>
                            <TableCell component="th">Cópias</TableCell>
                            <TableCell component="th">Tipo</TableCell>
                            <TableCell component="th">Data</TableCell>

                        </TableRow>
                    </TableHead>
                    <TableBody>

                        {impressoesFiltradas.length > 0 ? impressoesFiltradas.map((impressao, index) => (
                                <TableRow key={impressao.impressao._id+index}>

                                    <TableCell scope="row">
                                        {impressao.impressao.documento?.descricao}
                                        <br />
                                        <Typography variant="caption">
                                            {impressao.impressao.descricao}
                                        </Typography>
                                    </TableCell>
                                    <TableCell scope="row">{impressao.impressao.copias}</TableCell>
                                    <TableCell scope="row">{impressao.impressao.tipo}</TableCell>
                                    <TableCell scope="row">{impressao.impressao.data?.toGMTString()}</TableCell>

                                </TableRow>
                            )) :
                            <EmptyRow info="nenhuma impressão recente para mostrar" />
                        }
                    </TableBody>
                </Table>
            </Paper>
        </>
    )
}