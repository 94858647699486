import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import { Accordion, AccordionDetails, AccordionSummary, Box, IconButton } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import Skeleton from '@material-ui/lab/Skeleton';
import { Dialog } from '@material-ui/core';
import { DialogActions, DialogContent, DialogTitle } from '../../../util/dialogComponents';
import AuthService from '../../../AuthService';
import { createLocal, getGeneralSettings, saveSettings } from '../../../util/Config';
import HeaderAppBar from '../../header';
import { ipc } from '../../../util/electronUtil';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router';

export default function SettingsPrinter(props) {
    const service = new AuthService()
    const accessKey = localStorage.getItem("_accessKey");
    const [newPrintLocation, setNewPrintLocation] = useState()
    const [loading, setLoading] = useState(false)
    const [openReset, setOpenReset] = useState(false)
    const [passwordReset, setPasswordReset] = useState('')
    const [settings, setSettings] = useState(getGeneralSettings())
    // eslint-disable-next-line no-unused-vars
    const [ipcPrinters, setIpcPrinters] = useState(buscarImpressoras())
    const { enqueueSnackbar } = useSnackbar();
    const history = useHistory();

    const handleNovoLocal = () => {

        const empresaUUID = localStorage.getItem("_accessKey");

        let newPrintLocation = {
            empresaUUID: empresaUUID,
            descricao: '',
            chave: ''
        }

        setNewPrintLocation(newPrintLocation)
    }

    function buscarImpressoras() {
        return ipc.sendSync('show-prints') || []
    }

    useEffect(() => {
        buscarLocaisImpressao()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [accessKey])

    function buscarLocaisImpressao() {

        setLoading(true)

        service.get(`/api/localImpressao/list/${accessKey}`).then(locaisDeImpressaoResp => {
            console.log("Locais cadastrados no servidor", locaisDeImpressaoResp)

            let printLocations = []
            let settingsCopy = Object.assign({}, settings)


            locaisDeImpressaoResp.map(localImpressao => {

                const localEncontrado = settingsCopy.printLocations.find(e => localImpressao.chave === e?.chave)

                if (!localEncontrado) {
                    printLocations.push(createLocal(localImpressao))
                } else {
                    printLocations.push(localEncontrado)
                }
                return null;
            })

            if (printLocations.length > 0) {
                settingsCopy.printLocations = printLocations
                
                setSettings(settingsCopy)
                saveSettings(settingsCopy)
            }

            
        }).catch(err => {
            console.log("Erro buscar locais de notificação", err)
            enqueueSnackbar('Erro buscar locais de notificação. Detalhe:' + err, { variant: 'error' })
        }).finally(() => {
            setLoading(false)
        })
    }

    const handleChangeNovo = (event) => {
        const name = event.target.name
        const value = event.target.value.toUpperCase()

        setNewPrintLocation({ ...newPrintLocation, [name]: value })
    }

    const handleSalvarNovoLocal = () => {

        console.log('handleSalvarNovoLocal', newPrintLocation)

        if (!newPrintLocation) {
            enqueueSnackbar("Preencha os campos corretamente", { variant: "error" })
            return
        }

        if (newPrintLocation.descricao === '') {
            enqueueSnackbar("Preencha o campo descricao do local", { variant: "error" })
            return
        }

        if (newPrintLocation.chave === '') {
            enqueueSnackbar("Preencha o campo chave", { variant: "error" })
            return
        }

        service.post("/api/localImpressao/create", newPrintLocation).then(resp => {
            //ADICIONA LOCAL ENCONTRADO AS CONFIGURACOES
            console.log("Adicionando local na config", resp)

            let settingsCopy = Object.assign({}, settings)

            settingsCopy.printLocations.push(createLocal(resp))

            setSettings(settingsCopy)

            // saveSettings(settings)
            setNewPrintLocation()

            enqueueSnackbar('Local salvo com sucesso.', { variant: 'success' })
        }).catch(err => {
            console.log(err);
            enqueueSnackbar('Erro ao salvar. Detalhe' + err, { variant: 'error' })
        })
    }

    const handleCancelarCriacao = () => {
        setNewPrintLocation()
    }


    const onChangeFieldsPrinter = (event, indexPrintLocation, indexPrinter) => {
        let settingsCopy = Object.assign({}, settings)

        let printer = settingsCopy.printLocations[indexPrintLocation].Printers[indexPrinter]

        const name = event.target.name
        const value = event.target.value

        printer[name] = value

        console.log('settingsCopy', settingsCopy)

        setSettings(settingsCopy)
    }


    const salvarConfiguracoes = () => {
        console.log("Salvando as configurações", settings)

        saveSettings(settings)
        enqueueSnackbar('Configurações salvas com sucesso.', { variant: 'success' })
        history.push('/home')
    }

    const changePassword = (event) => {
        setPasswordReset(event.target.value)
    }

    const resetarConfiguracoes = () => {

        if (passwordReset === "balanca123") {
            const accessKey = localStorage.getItem("_accessKey");

            localStorage.clear();
            localStorage.setItem("_accessKey", accessKey)

            setSettings({})
            setPasswordReset('')

            buscarLocaisImpressao();
            enqueueSnackbar('Configurações resetadas com sucesso.', { variant: 'success' })
            setOpenReset(false)

        } else {
            enqueueSnackbar('A senha está errada, as configurações se manteram as mesmas', { variant: 'warning' })
        }
    }

    const deleteImpressora = (event, indexPrintLocation, indexPrinter) => {
        let settingsCopy = Object.assign({}, settings)

        let printers = settingsCopy.printLocations[indexPrintLocation].Printers

        printers.splice(indexPrinter, 1)

        console.log('deleteImpressora', settingsCopy)

        setSettings(settingsCopy)
    }

    const addImpressora = (event, indexPrintLocation) => {
        let settingsCopy = Object.assign({}, settings)

        let novaImpressora = { PrinterName: '', qtdImpressoes: 1 }

        let printers = settingsCopy.printLocations[indexPrintLocation].Printers

        if (printers.length) {
            printers.push(novaImpressora)
        }

        console.log('addImpressora', settingsCopy)

        setSettings(settingsCopy)
    }

    const handleChangeConfiguracoes = (event, indexPrintLocation) => {

        let settingsCopy = Object.assign({}, settings)

        let printLocation = settingsCopy.printLocations[indexPrintLocation]

        const name = event.target.name
        const value = event.target.value

        printLocation[name] = value

        console.log('settingsCopy', settingsCopy)

        setSettings(settingsCopy)
    }

    const handleDeleteLocal = (id) => {

        let settingsCopy = Object.assign({}, settings)

        service
            .delete(`/api/localImpressao/delete/${id}`)
            .then(resp => {
                const index = settingsCopy.printLocations.findIndex(el => el.localObj._id === id);

                settingsCopy.printLocations.splice(index, 1);

                setSettings(settingsCopy)
                enqueueSnackbar(`Local excluído com sucesso`, { variant: 'success' })

            })
            .catch(err => {
                console.log(err);
                this.props.enqueueSnackbar('Erro ao excluir local. ' + err, { variant: 'error' })

            })
    }

    return (
        <div>
            <HeaderAppBar />

            <Container maxWidth="sm">

                {newPrintLocation ? (
                    <Box pt={10}>
                        <Grid container spacing={2} direction='column'>
                            <Grid item xs >
                                <Typography variant="h4" component="h1">
                                    Novo local de impressão
                                </Typography>
                            </Grid>

                            <Grid item xs >
                                <TextField
                                    value={newPrintLocation.descricao || ""}
                                    onChange={handleChangeNovo}
                                    fullWidth
                                    label="Descrição do local"
                                    placeholder="Ex: CAIXA, COZINHA"
                                    name="descricao"
                                    variant="outlined" />
                            </Grid>

                            <Grid item xs >
                                <TextField
                                    value={newPrintLocation.chave || ""}
                                    onChange={handleChangeNovo}
                                    fullWidth
                                    placeholder="Ex: CAIXA, NC, NZ, NN"
                                    label="Chave/sigla do local de impressão"
                                    name="chave"
                                    variant="outlined" />

                            </Grid>

                            <Grid item xs={12}>
                                <Grid container spacing={2} direction='row'>
                                    <Grid item xs={6}>
                                        <Button fullWidth variant="contained" color="primary" onClick={handleSalvarNovoLocal} >
                                            Salvar
                                        </Button>
                                    </Grid>

                                    <Grid item xs={6}>
                                        <Button fullWidth color="primary" onClick={handleCancelarCriacao}>
                                            Cancelar
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                ) : (
                    <Box pt={10}>
                        <Grid container spacing={2} direction='column'>
                            <Grid item xs >
                                <Typography variant="h4" component="h1">
                                    Locais de impressão
                                </Typography>
                            </Grid>

                            <Grid item xs >
                                <Button
                                    startIcon={<AddIcon />}
                                    variant="outlined"
                                    onClick={handleNovoLocal}>
                                    Novo local de impressão
                                </Button>
                            </Grid>


                            <Grid item xs >
                                {loading ? (
                                    <React.Fragment>
                                        <Box pt={1}>
                                            <Skeleton variant="rect" height={40} />
                                        </Box>

                                        <Box pt={1}>
                                            <Skeleton variant="rect" height={40} />
                                        </Box>

                                        <Box pt={1}>
                                            <Skeleton variant="rect" height={40} />
                                        </Box>
                                    </React.Fragment>
                                ) :
                                    settings.printLocations.map((printLocation, indexPrintLocation) => (
                                        <Accordion key={indexPrintLocation}>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel1a-content"
                                                id="panel1a-header"  >
                                                <Typography >{printLocation.localObj.descricao + ' - ' + (printLocation.localObj.chave || '')}</Typography>
                                            </AccordionSummary>

                                            <AccordionDetails>

                                                <Grid container spacing={2} alignItems="center" >

                                                    {printLocation.Printers.map((printer, indexPrinter) => (

                                                        <React.Fragment key={indexPrinter}>
                                                            <Grid xs={7} item  >
                                                                <FormControl fullWidth>
                                                                    <TextField
                                                                        select
                                                                        defaultValue=""
                                                                        variant="outlined"
                                                                        value={printer.PrinterName}
                                                                        onChange={(event) => onChangeFieldsPrinter(event, indexPrintLocation, indexPrinter)}
                                                                        inputProps={{
                                                                            name: 'PrinterName',
                                                                            id: 'PrinterName-id',
                                                                        }}
                                                                        label="Impressora"
                                                                    >

                                                                        {ipcPrinters?.map(print => (
                                                                            <MenuItem key={print.name} value={print.name}>{print.name}</MenuItem>
                                                                        ))}
                                                                    </TextField>
                                                                </FormControl>
                                                            </Grid>

                                                            <Grid xs={3} item >
                                                                <TextField
                                                                    fullWidth
                                                                    id="qtdImpressoes"
                                                                    label="Cópias"
                                                                    defaultValue={printer.qtdImpressoes}
                                                                    onChange={(event) => onChangeFieldsPrinter(event, indexPrintLocation, indexPrinter)}
                                                                    type="number"
                                                                    name='qtdImpressoes'
                                                                    variant="outlined"
                                                                    InputLabelProps={{
                                                                        shrink: true,
                                                                        inputMode: 'numeric',
                                                                        min: "1"
                                                                    }}
                                                                />
                                                            </Grid>


                                                            <Grid item xs={1}>
                                                                <IconButton
                                                                    size="small"
                                                                    title="Excluír impressora"
                                                                    disabled={indexPrinter < 1} onClick={(e) => deleteImpressora(e, indexPrintLocation, indexPrinter)}>
                                                                    <DeleteIcon />
                                                                </IconButton>
                                                            </Grid>

                                                            <Grid item xs={1}>
                                                                <IconButton
                                                                    size="small"
                                                                    title="Adicionar nova impressora"
                                                                    disabled={printLocation.Printers.length >= 10} onClick={(e) => addImpressora(e, indexPrintLocation)}>
                                                                    <AddIcon />
                                                                </IconButton>
                                                            </Grid>


                                                        </React.Fragment>
                                                    ))}


                                                    <Grid xs={12} item >
                                                        <Typography variant="caption" display="block" gutterBottom>
                                                            Configurações para modo texto (V1)
                                                        </Typography>
                                                    </Grid>

                                                    <Grid xs={4} item >
                                                        <TextField
                                                            fullWidth
                                                            id="QuantityColumns"
                                                            label="Número de colunas"
                                                            value={printLocation.QuantityColumns}
                                                            onChange={(event) => handleChangeConfiguracoes(event, indexPrintLocation)}
                                                            type="number"
                                                            name='QuantityColumns'
                                                            variant="outlined"
                                                            size="small"
                                                        />
                                                    </Grid>

                                                    <Grid xs={4} item >
                                                        <TextField
                                                            fullWidth
                                                            style={{ marginLeft: "5px" }}
                                                            id="FontSize"
                                                            label="Tamanho da fonte"
                                                            value={printLocation.FontSize}
                                                            onChange={(event) => handleChangeConfiguracoes(event, indexPrintLocation)}
                                                            type="number"
                                                            name='FontSize'
                                                            variant="outlined"
                                                            size="small"
                                                        />
                                                    </Grid>

                                                    <Grid xs={4} item >

                                                    </Grid>

                                                    <Grid xs={12} item >
                                                        <Typography variant="caption" display="block" gutterBottom>
                                                            Configurações modo gráfico (V2)
                                                        </Typography>
                                                    </Grid>

                                                    <Grid xs={3} item >
                                                        <TextField
                                                            fullWidth
                                                            id="pageWidth"
                                                            label="Largura"
                                                            value={printLocation.pageWidth}
                                                            onChange={(event) => handleChangeConfiguracoes(event, indexPrintLocation)}
                                                            name='pageWidth'
                                                            placeholder="ex: 260px"
                                                            variant="outlined"
                                                            size="small"
                                                        />
                                                    </Grid>

                                                    <Grid xs={3} item >
                                                        <TextField
                                                            fullWidth
                                                            id="pageMargin"
                                                            label="Margens"
                                                            value={printLocation.pageMargin}
                                                            onChange={(event) => handleChangeConfiguracoes(event, indexPrintLocation)}
                                                            name='pageMargin'
                                                            variant="outlined"

                                                            placeholder="ex: 0 0 0 10px"
                                                            size="small"
                                                        />
                                                    </Grid>

                                                    <Grid xs={3} item >
                                                        <TextField
                                                            fullWidth
                                                            select
                                                            defaultValue=""
                                                            name='pagePreview'
                                                            id="pagePreview"
                                                            label="Impressão"
                                                            value={printLocation.pagePreview}
                                                            onChange={(event) => handleChangeConfiguracoes(event, indexPrintLocation)}
                                                            variant="outlined"
                                                            size="small"
                                                        >
                                                            <MenuItem value="false">Na impressora</MenuItem>
                                                            <MenuItem value="true">Em tela</MenuItem>
                                                        </TextField>
                                                    </Grid>

                                                    <Grid xs={3} item >
                                                        <TextField
                                                            fullWidth
                                                            id="fontSize"
                                                            label="Fonte (%)"
                                                            type='number'
                                                            value={printLocation.fontSize}
                                                            onChange={(event) => handleChangeConfiguracoes(event, indexPrintLocation)}
                                                            name='fontSize'
                                                            variant="outlined"
                                                            placeholder="100%"
                                                            size="small"
                                                        />
                                                    </Grid>


                                                    <Grid xs={12} item>
                                                        <Box>
                                                            <Button startIcon={<DeleteOutlineIcon />} onClick={() => handleDeleteLocal(printLocation.localObj._id)}>
                                                                Excluir local de impressão
                                                            </Button>
                                                        </Box>
                                                    </Grid>
                                                </Grid>

                                            </AccordionDetails>
                                        </Accordion>

                                    ))
                                }
                            </Grid>

                            <Grid item xs={12}>
                                <Grid container spacing={2} direction='row'>
                                    <Grid item xs={6}>
                                        <Button fullWidth variant="contained" color="primary" onClick={salvarConfiguracoes} >
                                            Salvar configurações
                                        </Button>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Button fullWidth onClick={() => setOpenReset(true)} >
                                            Resetar configurações
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                )}

            </Container>

            <div >
                <Dialog open={openReset}
                    onClose={() => setOpenReset(false)}
                    fullWidth
                    maxWidth="xs">
                    <DialogTitle onClose={() => setOpenReset(false)}>
                        Digite a senha para resetar as configurações
                    </DialogTitle>
                    <DialogContent>
                        <FormControl fullWidth variant="outlined">
                            <TextField
                                onChange={changePassword}
                                id="outlined-password-input"
                                label="Password"
                                value={passwordReset}
                                type="password"
                                variant="outlined"
                            />
                        </FormControl>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" color="primary" onClick={resetarConfiguracoes} >
                            Confirmar
                        </Button>
                    </DialogActions>

                </Dialog>
            </div>
        </div >
    );
}