import React, { useEffect, useRef, useState } from 'react';
import { Paper, Box, Grid, Button } from '@material-ui/core';
import LinearProgress from '@material-ui/core/LinearProgress';
import StopIcon from '@material-ui/icons/Stop';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import AuthService from '../../AuthService';
import { useDispatch, useSelector } from 'react-redux';
import { getGeneralSettings } from '../../util/Config';
import { useSnackbar } from 'notistack';
import { useInterval } from './useInterval';

export default function PrintPolling() {
    const uuid = useRef(localStorage.getItem("_UUID") || '*UUID NÃO CONFIGURADO*')
    const [statusPolling, setStatusPolling] = useState('INICIADO')
    const [loading, setloading] = useState(false)
    const settings = getGeneralSettings()
    const { poolingTime } = settings.general
    const service = new AuthService()
    const dispatch = useDispatch()
    const accessKey = localStorage.getItem("_accessKey")
    const { enqueueSnackbar } = useSnackbar()
    const printLocations = settings.printLocations;

    let impressoesFiltradas = useRef([])
    var myInterval = useRef()
    let impressoes = useSelector(state => state.impressoes)
    let savedPrintPendind = useRef()


    impressoesFiltradas.current = impressoes.filter(e => !e.impressao.impresso)

    useEffect(() => {

        myInterval.current = new useInterval(polling, poolingTime * 1000)

        return () => {
            console.log('pararTodasAsBuscas')
            pararTodasAsBuscas()
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    function polling() {
        console.log('polling', new Date())


        const imp = impressoesFiltradas.current.filter(e => e.impressora.PrinterName)

        console.log('impressoes não impressas com impressora informada', imp)

        if (imp.length <= 0) {
            savedPrintPendind.current = JSON.parse(localStorage.getItem('PRINT_PENDING'))

            console.log('savedPrintPendind', savedPrintPendind.current)

            if (savedPrintPendind.current && savedPrintPendind.current.length > 0) {
                savedPrintPendind.current.map(e => marcarImpressoesComoImpresso(e.impressao))
            } else {

                buscarImpressoesPendentes()
            }
        }
    }

    function marcarImpressoesComoImpresso(documento) {

        console.log('marcarImpressoesComoImpresso', documento)
        const json = { "statusImpressao": "IMPRESSO" }

        service.put(`/api/impressao/update/${documento._id}`, json)
            .then(retornoDocumento => {

                retornoDocumento.impresso = true
                retornoDocumento.impressoAPI = true

                // console.log('savedPrintPendindCopy', retornoDocumento)

                dispatch({ type: 'UPDATE_IMPRESSAO_PENDING', impressao: retornoDocumento })

                enqueueSnackbar('Impressão marcadas como impresso', { variant: 'success' })
                console.log("Impressão marcadas como impresso", retornoDocumento)
            }).catch(err => {
                console.error("Erro ao notificar pedido como impresso", documento, err)
                enqueueSnackbar('Erro ao notificar pedido como impresso. Veja o log.', { variant: 'error' })
            })
    }

    function pararTodasAsBuscas() {
        console.log("pararTodasAsBuscas");

        setStatusPolling('PAUSADO')

        if (myInterval.current) {
            myInterval.current.stop()
        }
    }

    function iniciarBusca() {
        console.log("iniciarBusca");

        setStatusPolling('INICIADO')

        if (myInterval.current) {
            myInterval.current.start()
        }
    }

    function buscarImpressoesPendentes() {
        setloading(true)

        service.get(`/api/impressao/pooling/${accessKey}`).then(impressoesResp => {

            console.log('buscarImpressoesPendentes', impressoesResp)
            addImpressao(impressoesResp)

            if(!!getGeneralSettings()?.general.empresasAdicionadas && getGeneralSettings().general.empresasAdicionadas.length > 0){
                getGeneralSettings().general.empresasAdicionadas.map((empresa)=>{
                    service.get(`/api/impressao/pooling/${empresa.token}`).then(impressoesRespExtr => {
                        console.log('buscarImpressoesPendentes', impressoesRespExtr)
                        addImpressao(impressoesRespExtr)
                    }).catch(e => {
                        console.log("Erro buscar pedidos pendentes", e)
                        enqueueSnackbar(`Erro buscar pedidos pendentes. Detalhe: ${e}`, { variant: 'error' });
                    })
                    return null;
                })
            }

        }).catch(e => {
            console.log("Erro buscar pedidos pendentes", e)
            enqueueSnackbar(`Erro buscar pedidos pendentes. Detalhe: ${e}`, { variant: 'error' });
        }).finally(() => {
            setloading(false)
        })
    }

    function addImpressao(impressaoList) {
        let newImpressoesSocket = [];

        impressaoList.map((impressao) => {

            const tipoConteudo = impressao.conteudo.tipo

            console.log('tipoConteudo', tipoConteudo)

            if (tipoConteudo === 'JSON') {
                const lineUuid = {
                    type: 'text',
                    value: uuid.current,
                    style: `text-align:center;`,
                    css: { "font-size": "8px" }
                };

                impressao.conteudo.linhas.push(lineUuid)
            } else if (tipoConteudo === 'URL') {

            } else {
                const lineUuid = {
                    texto: uuid.current,
                    alinhamento: 'C',
                };

                impressao.conteudo.linhas.push(lineUuid)
            }

            const printLocation = printLocations.find(elem => elem.chave === impressao.localImpressao);

            // console.log('configImpressora', printLocation);

            if (!printLocation) {

                enqueueSnackbar(`Local de impressão ${impressao.localImpressao} não cadastrado.`, { variant: 'error' });
                console.error(`Local de impressão ${impressao.localImpressao} não cadastrado.`);

            } else {

                printLocation.Printers.map(printer => {

                    const qtdImpressoes = parseInt(printer.qtdImpressoes) || 1;

                    for (var i = 0; i < qtdImpressoes; i++) {

                        // console.log('qtdImpressoes', i);

                        newImpressoesSocket.push({
                            impressao,
                            impressora: printer,
                            configuracao: printLocation,
                            indexImpressao: i
                        });
                    }
                    return null;
                });
            }
            return null;
        });

        dispatch({ type: 'ADD_IMPRESSAO', impressoes: newImpressoesSocket });
    }

    return (

        <Box pt={5}>
            <Paper style={{ padding: 10 }}>


                {statusPolling === "INICIADO" ?
                    <Grid container alignItems='center'>
                        <Grid item xs={6} >
                            <Button onClick={pararTodasAsBuscas} startIcon={<StopIcon />}> Parar busca de impressões</Button>
                        </Grid>

                        <Grid item xs={6}>
                            {!loading &&
                                <LinearProgress />
                            }
                        </Grid>


                    </Grid>

                    :

                    <Button onClick={iniciarBusca} startIcon={<PlayArrowIcon />}> Iniciar busca de impressões</Button>
                }
            </Paper>
        </Box>

    );
}
