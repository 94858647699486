import { createStore } from 'redux';
import { clearImpressao, createImpressao, initialImpressoes, updateImpressao, updateImpressaoPendente } from './Impressoes';


const INITIAL_STATE = {
    impressoes: initialImpressoes(),
}

function reciveAction(state = INITIAL_STATE, action) {
    switch (action.type) {
        case 'ADD_IMPRESSAO':
            return createImpressao(state, action);
        case 'UPDATE_IMPRESSAO':
            return updateImpressao(state, action);
        case 'UPDATE_IMPRESSAO_PENDING':
                return updateImpressaoPendente(state, action);
        case 'CLEAR_IMPRESSAO':
            return clearImpressao(state, action);
        default:
            return state;
    }
}

const store = createStore(reciveAction);

export default store;