import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import { Accordion, AccordionDetails, AccordionSummary, Box, IconButton } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import HeaderAppBar from '../../header';
import { getGeneralSettings, saveSettings } from '../../../util/Config';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';

export default function GeneralSettings() {    
    const [empresasAdicionadas, setEmpresasAdicionadas] = useState( !!getGeneralSettings()?.general.empresasAdicionadas ? getGeneralSettings().general.empresasAdicionadas : [])
    const [empresaToken, setEmpresaToken] = useState("")
    const [empresaName, setEmpresaName] = useState("")
    const [settings, setSettings] = useState(getGeneralSettings())
    const { enqueueSnackbar } = useSnackbar();
    const history = useHistory();
   
    const handleChangeFields = (event) => {
        const name = event.target.name
        const type = event.target.type
        let value = event.target.value

        if (type === 'checkbox') {
            value = event.target.checked
        }

        console.log('handleChangeFields', name, value)

        const general = {...settings.general,  [name]: value}

        setSettings({ ...settings, general })
    }

    const handleSalve = () => {
        saveSettings(settings)
        enqueueSnackbar("Configurações salvas", { variant: "success" })
        history.push('/home')
    }

    const handleAddTokenEmpresa = () =>{
        
        if(empresaToken?.trim().length < 36){
            enqueueSnackbar("O token da empresa deve ter 36 dígitos",{variant: "error"})
            return;
        }

        let copyEmpresasAdicionadas = Object.assign([],empresasAdicionadas);
        let objectEmpresa = {name: empresaName, token: empresaToken.trim()}

        copyEmpresasAdicionadas.push(objectEmpresa)
        setEmpresasAdicionadas(copyEmpresasAdicionadas)
        setEmpresaName("")
        setEmpresaToken("")
    }

    useEffect(()=>{
        const general = {...settings.general,  empresasAdicionadas: empresasAdicionadas}
        setSettings({ ...settings, general })

    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[empresasAdicionadas])

    const handleDeleteEmpresa = (empresa) =>{
        let copyEmpresasAdicionadas = empresasAdicionadas.filter((empresaFilter)=> empresa.token !== empresaFilter.token)
        setEmpresasAdicionadas(copyEmpresasAdicionadas)
    }

    return (
        <React.Fragment>
            <HeaderAppBar />

            <Container maxWidth="sm">

                <Box pt={10}>
                    <Typography variant="h4" component="h1">
                        Configurações gerais
                    </Typography>
                </Box>

                <Box pt={5} pb={5}>

                    <Grid container spacing={2} direction='column'>

                        <Grid xs={4} item  >
                            <TextField
                                id="poolingTime"
                                label="Tempo para buscar"
                                name="poolingTime"
                                variant="outlined"
                                fullWidth
                                value={settings?.general?.poolingTime}
                                type="number"
                                onChange={handleChangeFields}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">Segundos</InputAdornment>,
                                }}
                            />
                        </Grid>

                        <Grid xs={4} item  >
                            <TextField
                                id="timeToPrint"
                                label="Tempo para imprimir"
                                name="timeToPrint"
                                variant="outlined"
                                fullWidth
                                value={settings?.general?.timeToPrint}
                                type="number"
                                onChange={handleChangeFields}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">Segundos</InputAdornment>,
                                }}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <FormControlLabel
                                control={<Switch size="small" name='sound' checked={settings?.general?.sound} onChange={handleChangeFields} />}
                                label="Ativar som para pedidos"
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <FormControlLabel
                                control={<Switch name='socket' size="small" checked={settings?.general?.socket} onChange={handleChangeFields} />}
                                label="Receber os pedidos por websocket"
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <Accordion>
                                <AccordionSummary

                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"  >
                                    <Typography >{"Outras empresas"}</Typography>
                                </AccordionSummary> 

                                <AccordionDetails>
                                    <Grid container direction='column'>
                                        <Grid item>
                                            <Grid container spacing={2} alignItems="center" >
                                                <Grid xs={5} item  >
                                                    <TextField
                                                        fullWidth
                                                        id="EnterpriseName-id"
                                                        label="Nome da empresa"
                                                        value={empresaName}
                                                        onChange={(event) => {setEmpresaName(event.target.value)}}
                                                        name='qtdImpressoes'
                                                        variant="outlined"
                                                        InputLabelProps={{
                                                            name: 'EnterpriseName',
                                                            id: 'EnterpriseName-id',
                                                        }}
                                                    />
                                                </Grid>
                                                
                                                <Grid xs={6} item  >
                                                    <TextField
                                                        fullWidth
                                                        id="EnterpriseToken"
                                                        label="Token da empresa"
                                                        value={empresaToken}
                                                        onChange={(event) => {setEmpresaToken(event.target.value)}}
                                                        name='qtdImpressoes'
                                                        variant="outlined"
                                                        InputLabelProps={{
                                                            name: 'EnterpriseToken',
                                                            id: 'EnterpriseToken-id',
                                                        }}
                                                    />
                                                </Grid>

                                                <Grid item xs={1}>
                                                    <IconButton
                                                        size="small"
                                                        title="Adicionar empresa"
                                                        disabled={false} 
                                                        onClick={handleAddTokenEmpresa}>
                                                        <AddIcon />
                                                    </IconButton>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item style={{marginTop: '8px'}}>
                                            {empresasAdicionadas.map((empresa, index)=>(
                                                <Grid key={index} container spacing={2} alignItems="center">
                                                    <Grid xs={5} item  >
                                                        <TextField
                                                            fullWidth
                                                            id="EnterpriseName-id"
                                                            label="Nome da empresa"
                                                            value={empresa.name}
                                                            onChange={(event) => {}}
                                                            name='qtdImpressoes'
                                                            variant="outlined"
                                                            InputLabelProps={{
                                                                name: 'EnterpriseName',
                                                                id: 'EnterpriseName-id',
                                                            }}
                                                        />
                                                    </Grid>
                                                    
                                                    <Grid xs={6} item  >
                                                        <TextField
                                                            fullWidth
                                                            id="EnterpriseToken"
                                                            label="Token da empresa"
                                                            value={empresa.token}
                                                            onChange={(event) => {}}
                                                            name='qtdImpressoes'
                                                            variant="outlined"
                                                            InputLabelProps={{
                                                                name: 'EnterpriseToken',
                                                                id: 'EnterpriseToken-id',
                                                            }}
                                                        />
                                                    </Grid>

                                                    <Grid item xs={1}>
                                                        <IconButton
                                                            size="small"
                                                            title="Excluír empresa"
                                                            disabled={false} onClick={(e) => {handleDeleteEmpresa(empresa)}}>
                                                            <DeleteIcon />
                                                        </IconButton>
                                                    </Grid>
                                                </Grid>
                                            ))}
                                        </Grid>
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>
                        </Grid>

                    </Grid>

                </Box>

                <Button variant="contained" color="primary" onClick={() => handleSalve()} >
                    Salvar configurações
                </Button>

            </Container>
        </React.Fragment>
    );
}
