import { Table, TableBody, TableRow, Paper, Button, TableCell, TableHead } from "@material-ui/core"
import React, { useState } from "react"
import { useSelector } from "react-redux"
import RemoverItem from "../../remove-item"
import MoreVertIcon from '@material-ui/icons/MoreVert';
import ErrorIcon from '@material-ui/icons/Error';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import EmptyRow from "../emptyRow";

export default function PedidosNaoImpresso() {
    const [open, setOpen] = useState(false)
    const [imprimir, setImprimir] = useState("")

    const impressoes = useSelector(state => state.impressoes)

    const handleClickDelete = (impressao) => {
        setOpen(!open)
        setImprimir(impressao)
    }

    const impressoesFiltradas = impressoes.length > 0 && impressoes.filter(e => !e.impressao.impresso)

    return (
        <React.Fragment>
            <Paper >
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell component="th"/>
                            <TableCell component="th">#</TableCell>
                            <TableCell component="th">local</TableCell>
                            <TableCell component="th" align="center">Descrição</TableCell>
                            <TableCell component="th">Data/Hora</TableCell>
                            <TableCell component="th"/>

                        </TableRow>
                    </TableHead>
                    <TableBody>

                        {impressoesFiltradas.length > 0 ? impressoesFiltradas.map((impressao, index) => (
                            <TableRow key={impressao.impressao._id+index}>
                                <TableCell align="center"> {!impressao.impressora.PrinterName ?
                                    <Tooltip title={`Impressora não informanda para o local: ${impressao.impressao.localImpressao}`}>
                                        <IconButton >
                                            <ErrorIcon color="error" />
                                        </IconButton>
                                    </Tooltip> :
                                    <CheckCircleIcon color="secondary" />}
                                </TableCell>
                                <TableCell align="center"> {impressao.impressao.identificador}</TableCell>
                                <TableCell align="center"> {impressao.impressao.localImpressao}</TableCell>
                                <TableCell scope="row">{impressao.impressao.descricao}</TableCell>
                                <TableCell scope="row">{impressao.impressao.dataCriacao}</TableCell>
                                <TableCell scope="row">
                                    <IconButton onClick={() => handleClickDelete(impressao.impressao)}>
                                        <MoreVertIcon />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        )) :

                            <EmptyRow info="nenhuma impressão pendente no momento" />
                        }

                    </TableBody>
                </Table>
            </Paper>
            <RemoverItem open={open} setOpen={setOpen} impressao={imprimir} />
        </React.Fragment>
    )
}