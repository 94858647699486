import React, { useEffect, useRef } from 'react';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import { Box } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import HeaderAppBar from '../header';
import PrintSocket from '../printSocket';
import { getGeneralSettings } from '../../util/Config';
import { Alert } from '@material-ui/lab';
import { useDispatch, useSelector } from 'react-redux';
import { ipc } from '../../util/electronUtil';
import { useSnackbar } from 'notistack';
import AuthService from '../../AuthService';
import PrintPolling from '../polling';
import Report from '../../util/Report';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { useInterval } from '../polling/useInterval';
import PedidosNaoImpresso from '../listPrint/notPrinted';
import PedidosJaImpresso from '../listPrint/printed';

export default function HomePrintMonitor() {
    const settings = useRef(getGeneralSettings())
    const { socket, sound, poolingTime, timeToPrint } = settings.current.general
    let impressoes = useRef([])
    let imprimindo = useRef(false)
    const { enqueueSnackbar } = useSnackbar();
    const service = new AuthService()
    const dispatch = useDispatch()
    const [tabSelected, setTabSelected] = React.useState(0);
    var myIntervalPrint = useRef()
    const empresasAdicionadas = getGeneralSettings()?.general.empresasAdicionadas ? getGeneralSettings().general.empresasAdicionadas : []

    impressoes.current = useSelector(state => state.impressoes)


    useEffect(() => {
        let currentImpressoesCopy = Object.assign([], impressoes.current)
        const savePrintPendind = currentImpressoesCopy.filter(e => !e.impressao.impressoAPI)

        let iguais = []

        savePrintPendind.map(e => {

            if (!iguais.length) {
                iguais.push(e)
            }

            let b = iguais.find(i => i.impressao._id === e.impressao._id)

            if (!b) {
                iguais.push(e)
            }

            return null;
        })

        console.log('useEffect -> savePrintPendind', iguais)

        localStorage.setItem('PRINT_PENDING', JSON.stringify(iguais))

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [impressoes.current])

    const handleChangeTab = (event, newValue) => {
        setTabSelected(newValue);
    };

    function TabPanel(props) {
        const { children, value, index, ...other } = props;

        return (
            <Typography
                component="div"
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && <Box pt={3}>{children}</Box>}
            </Typography>
        );
    }

    function printInterval() {
        if (Boolean(imprimindo.current) === false) {
            // console.log('printInterval -> impressoes.current', impressoes.current)

            const imp = impressoes.current.filter(e => !e.impressao.impresso && e.impressora.PrinterName);

            if (imp.length > 0) {
                console.log('impressao', imp[0]);

                printOut(imp[0]);
            }
        }

        console.log('printInterval', imprimindo.current, new Date())
    }



    useEffect(() => {

        // console.log('HOME_PRINT_MONITOR')

        myIntervalPrint.current = new useInterval(printInterval, timeToPrint * 1000)

        return () => {
            console.log('CLEAR_IMPRESSAO')
            dispatch({ type: 'CLEAR_IMPRESSAO', impressoes: [] })

            if (myIntervalPrint.current) {
                myIntervalPrint.current.stop()
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const printOut = (impressaoMontada) => {
        console.log('printOut', impressaoMontada)

        imprimindo.current = true

        const impressao = impressaoMontada.impressao;
        const printer = impressaoMontada.impressora;
        const tipoConteudo = impressao.conteudo.tipo
        const conteudo = impressao.conteudo.linhas;
        const configImpressora = impressaoMontada.configuracao;
        const qtdImpressoes = parseInt(printer.qtdImpressoes) || 1;

        if ("JSON" === tipoConteudo) {
            const versaoElectron = ipc.versions?.electron
            if (!versaoElectron) {
                console.error("É necessário atualizar a última versão do monitor parseint", versaoElectron)
                enqueueSnackbar('É necessário atualizar a última versão do monitor parseint. Entre em contato com o suporte', { variant: 'error' })
                return null;
            }

            const posPrinter = ipc.posPrinter;

            const title = `${impressao.identificador}_${impressao.localImpressao}_${impressaoMontada.indexImpressao}`

            const options = {
                preview: configImpressora.pagePreview === "true" ? true : false, // Preview in window or print
                width: configImpressora.pageWidth || "280px", //  width of content body
                margin: configImpressora.pageMargin || "0 5px 0 5px", // margin of content body
                copies: 1, // Number of copies to print
                timeOutPerLine: ((conteudo.length * 400) + 200),
                // timeOutPerLine: 10,
                silent: true,
                printerName: printer.PrinterName,
                pathTemplate: ipc.URL_MAIN_PAGE + "pos.html",
                fontSize: (configImpressora.fontSize || "100") + "%",
                title: title
            }

            //caso precise editar o template html utilizar como base este arquivo https://github.com/parseint/electron-pos-printer/blob/master/dist/libs/body-init.js

            console.log('printOut -> options', options)

            if (printer.PrinterName) {

                posPrinter.print(conteudo, { ...options, title: title })
                    .then(({ complete, reject }) => {

                        console.log(`Print V2 -> .then complete:${complete} | reject ${reject} ` + title)

                        if (!complete) {

                            imprimindo.current = false
                            console.error(`posPrinter.print -> ${!complete}, Falha ao imprimir na versão 2 - Impressora: ${printer.PrinterName} `)
                            enqueueSnackbar('Falha ao imprimir na V2 - Impressora ' + printer.PrinterName, { variant: 'error' })
                        } else {
                            notificarDocumentoComoImpresso(impressao, title, qtdImpressoes, tipoConteudo)
                        }
                    })
                    .catch((error) => {

                         /* SE ERRO FOR TimedOut = Marca pedido como impresso mesmo assim */
                         if (error && error.toString().toLocaleLowerCase().indexOf("timedout") >= 0) {

                             setTimeout(function () {
                                 console.warn(`TimedOut ao imprimir, por isso é esperado alguns segundos antes de voltar a imprimir. Detalhe:  ${(timeToPrint + 1) * 1000} segundos`)
                                notificarDocumentoComoImpresso(impressao, title, qtdImpressoes, tipoConteudo)

                            }, (timeToPrint + 1) * 1000);

                        } else {

                            setTimeout(function () {
                                console.error('Falha ao imprimir na versão 2 - Impressora ' + printer.PrinterName + ' Detalhe: ' + error)

                                imprimindo.current = false

                            }, (timeToPrint + 1) * 1000);
                        }
                    })

            }  else {

                setTimeout(function () {
                    console.warn('Local de impressão ' + impressao.localImpressao + " sem impressora configurada");
                    enqueueSnackbar('Local de impressão ' + impressao.localImpressao + " sem impressora configurada", { variant: 'error' })
                    imprimindo.current = false

                },  (timeToPrint + 1) * 1000);

            }

        } else if ("URL" === tipoConteudo) {

            const url = conteudo[0].url;
            const title = `${impressao.identificador}_${impressao.localImpressao}_${impressaoMontada.indexImpressao}`

            let printerName = printer.PrinterName;
            let preview = configImpressora.pagePreview === "true" ? true : false

            let retorno = ipc.sendSync("print-nfc-url", {
                printerName,
                url,
                preview
            });

            if (retorno) {
                setTimeout(function () {
                    notificarDocumentoComoImpresso(impressao, title, 1, tipoConteudo)

               }, (timeToPrint + 1) * 1000);
            } else {

                setTimeout(function () {
                    console.error('Falha ao imprimir na versão 2 tipo -> URL - Impressora ' + printer.PrinterName)

                    imprimindo.current = false

                }, (timeToPrint + 1) * 1000);
            }

        } else {
            console.log("Iniciado V1 - Impressão")

            let template = Report.buildReport(impressao);
            console.log('Configuração impressora para ' + impressao.localImpressao, configImpressora)

            const title = `${impressao.identificador}_${impressao.localImpressao}_${impressaoMontada.indexImpressao}`

            let qtdImpressoes = 1; //SEMPRE 1 PQ MONTA AS IMPRESSORES //parseInt(printer.qtdImpressoes) || 1  
            console.log(`Imprimindo local ${configImpressora.localObj.chave} - Copias ${qtdImpressoes} - Impressora`, printer);

            let success = printPedido(template, configImpressora, printer.PrinterName, qtdImpressoes);

            if (success) {
                notificarDocumentoComoImpresso(impressao, title, qtdImpressoes, "V1")
            } else {
                imprimindo.current = false
                console.log('printOut -> V1', imprimindo.current)
            }
        }
    }

    function printPedido(Template, impressora, nome, printQuantity) {
        console.log("printPedido", impressora, nome, printQuantity);

        let success = true;

        let PrinterName = nome;
        let QuantityColumns = impressora.QuantityColumns;
        let FontSize = impressora.FontSize;


        if (!PrinterName || PrinterName === "") {

            console.log("IMPRESSORA NÂO CONFIGURADA PARA ", impressora.chave)
            enqueueSnackbar('Impressora não configurada para local ' + impressora.chave, { variant: 'warning' })

            throw new Error("Impressora não configurada para " + impressora.chave)

        } else {

            console.log("INICIANDO IMPRESSAO - COPIAS: " + printQuantity);

            for (var i = 0; i < printQuantity; i++) {
                console.log("IMPRIMINDO - COPIA: " + i);
                let retorno = ipc.sendSync('print-document-await', { PrinterName, Template, QuantityColumns, FontSize })

                if (!retorno) {
                    success = false;
                }
            }

            return success;
        }
    }

    const playAudio = () => {
        let bellSong = "./Assets/somCampainha.wav"
        let audio = new Audio(bellSong);
        audio.play();
    }

    function notificarDocumentoComoImpresso(documento, descricao, copias, tipo) {
        console.log("notificarDocumentoComoImpresso -> imprimindo.current", imprimindo.current)

        const json = { "statusImpressao": "IMPRESSO" }

        let docPrinted = Object.assign({}, documento)
        docPrinted.impresso = true
        docPrinted.descricao = descricao
        docPrinted.copias = copias
        docPrinted.tipo = tipo
        docPrinted.documento = documento
        docPrinted.data = new Date()

        service
            .put(`/api/impressao/update/${documento._id}`, json)
            .then(retornoDocumento => {

                docPrinted.impressoAPI = true

            }).catch(err => {
                console.error("Erro ao notificar pedido como impresso", documento, err)
                enqueueSnackbar('Erro ao notificar pedido como impresso. Veja o log.', { variant: 'error' })
            }).finally(() => {
                console.log("notificarDocumentoComoImpresso -> finally -> docPrinted", docPrinted)
                dispatch({ type: 'UPDATE_IMPRESSAO', impressao: docPrinted })

                imprimindo.current = false

                if (sound) {
                    playAudio()
                }
            })
    }


    return (
        <React.Fragment>
            <HeaderAppBar />
            <Container maxWidth="md">

                <Box pt={10}>
                    <Typography variant="h5" component="h1">
                        Monitor de impressões
                    </Typography>
                    <Typography variant='body1'>
                        Mantenha esta tela sempre aberta para receber as impressões automáticamente
                    </Typography>

                    <Grid container spacing={2} direction='column'>
                        <Grid item>
                            <Alert severity="info">
                                {/* <AlertTitle>Info</AlertTitle> */}
                                Modo de impressão - <strong>{`${socket ? 'Websocket' : `Polling: ${poolingTime} seg.`}`}</strong>
                                <br />
                                Aviso sonoro - <strong>{sound ? 'Ativo' : 'Inativo'}</strong>
                                <br/>
                                {empresasAdicionadas.length > 0 && (
                                    <>
                                        Empresas Adicionadas:  
                                        <strong>
                                            <ul style={{margin: '0px', paddingLeft: '20px'}}>
                                                {empresasAdicionadas.map((empresa, index)=>{
                                                    return(
                                                        <>
                                                            <li>
                                                            {empresa.name} 
                                                            </li>
                                                        </>
                                                    ) 
                                                })}
                                            </ul>
                                        </strong>
                                        <br/>
                                    </>
                                )}
                            </Alert>
                        </Grid>



                        <Grid item xs>
                            {socket ?
                                <PrintSocket />

                                :

                                <PrintPolling />

                            }
                        </Grid>

                        <Grid item xs>
                            <Tabs
                                value={tabSelected}
                                onChange={handleChangeTab}
                                indicatorColor="primary"
                                textColor="primary"
                                aria-label="Tipo de informação" >
                                <Tab label={`Pendentes ( ${impressoes.current?.filter(e => !e.impressao.impresso).length} )`} />
                                <Tab label={`Realizadas ( ${impressoes.current?.filter(e => e.impressao.impresso).length} )`} />
                            </Tabs>


                            <TabPanel value={tabSelected} index={0}>
                                <PedidosNaoImpresso />
                            </TabPanel>

                            <TabPanel value={tabSelected} index={1}>
                                <PedidosJaImpresso />
                            </TabPanel>

                        </Grid>

                    </Grid>
                </Box>
            </Container>

        </React.Fragment >
    );
}