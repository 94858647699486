
export function useInterval(fn, t) {
    
    var myInterval = setInterval(fn, t);

    console.log('myInterval', myInterval)
    
    this.stop = function () {
        console.log('stop', myInterval)

        if (myInterval) {
            clearInterval(myInterval);
            myInterval = null;
        }

        return this;
    }

    this.start = function() {
        if (!myInterval) {
            this.stop();
            myInterval = setInterval(fn, t);
            console.log('start', myInterval)
        }

        return this;
    }

    // this.reset = function(newT = t) {
    //     t = newT;
        
    //     console.log('reset', myInterval)

    //     return this.stop().start();

    // }
}