import React from 'react';
import { useState } from 'react';
import { useSnackbar } from 'notistack';
import Button from '@material-ui/core/Button';
import AuthService from '../AuthService'
import { Dialog } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { ipc } from "../util/electronUtil"
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import { DialogActions, DialogContent, DialogTitle } from '../util/dialogComponents';
import { useDispatch } from 'react-redux';


export default function RemoverItem({ open, setOpen, impressao }) {
  const [selectedPrint, setSelectedPrint] = useState("")
  const impressoras = ipc.sendSync('show-prints') || []
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch()

  const handleClickExclude = () => {

    marcarImpresso(impressao)
    setOpen(false)

  }

  const marcarImpresso = (impressaoPendente) => {
    var json = { "statusImpressao": "IMPRESSO" }
    let service = new AuthService()
    service
      .put(`/api/impressao/update/${impressaoPendente._id}`, json)
      .then(retornoDocumento => {
  
        retornoDocumento.impresso = true
        retornoDocumento.data = new Date()
  
        dispatch({ type: 'UPDATE_IMPRESSAO', impressao: retornoDocumento })
  
      }).catch(err => {
        console.error("Erro ao notificar pedido como impresso", impressaoPendente, err)
        enqueueSnackbar('Erro ao notificar pedido como impresso. Veja o log.', { variant: 'error' })
      })
  }

  const handleClickPrint = () => {


    const tipoConteudo = impressao.conteudo.tipo

    if ("JSON" === tipoConteudo) {
      printPedidoV2(impressao, selectedPrint, enqueueSnackbar)
    } else {
      //printPedidoV1(impressao, selectedPrint)
      enqueueSnackbar("Não é possível imprimir a versão de impressão ( V1 ), utilize e V2", { variant: "error" })
    }

    marcarImpresso(impressao)

    setOpen(false)

  }

  return (
    <div>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        fullWidth
        maxWidth="xs">
        <DialogTitle onClose={() => setOpen(false)}>
          Remover da fila de impressão
        </DialogTitle>

        <DialogContent>{/**value={}
            onChange={} */}

          <Grid
            container
            direction="row"
            alignItems="center"
            spacing={2}
            pt={2}>

            <Grid item xs={6}>
              <FormControl fullWidth>
                <TextField
                  select
                  variant="outlined"
                  value={selectedPrint.name ? selectedPrint.name : ""}
                  inputProps={{
                    name: 'PrinterName',
                    id: 'PrinterName-id',
                  }}
                  label="Impressora"
                >

                  {impressoras.map(print => (
                    <MenuItem key={print.name} value={print.name} onClick={() => { setSelectedPrint(print) }}>{print.name}</MenuItem>
                  ))}
                </TextField>
              </FormControl>
            </Grid>
          </Grid>

        </DialogContent>

        <DialogActions>
          <Button variant="outlined" onClick={handleClickPrint}>{"Imprimir"}</Button>
          <Button variant="outlined" onClick={handleClickExclude}>{"Marcar como impresso"}</Button>
        </DialogActions>

      </Dialog>
    </div>
  )
}

const printPedidoV2 = (impressao, printer, enqueueSnackbar) => {

  const versaoElectron = ipc.versions?.electron

  if (!versaoElectron) {
    console.error("É necessário atualizar a última versão do monitor parseint")
    enqueueSnackbar('É necessário atualizar a última versão do monitor parseint. Entre em contato com o suporte', { variant: 'error' })
    return;
  }

  const conteudo = impressao.conteudo.linhas;
  const posPrinter = ipc.posPrinter

  const options = {
    preview: false, // Preview in window or print
    width: "280px", //  width of content body
    margin: "0 5px 0 5px", // margin of content body
    copies: 1, // Number of copies to print
    timeOutPerLine: 700,
    silent: true,
    printerName: printer.name,
    pathTemplate: ipc.URL_MAIN_PAGE + "pos.html"
  };

  posPrinter.print(conteudo, options)
    .then(() => {
      console.log("Deu boa a impressão - V2")
    })
    .catch((error) => {
      console.error("Falha ao imprimir na versão 2 - Impressora " + printer.PrinterName);
      console.error(error);
    });
}