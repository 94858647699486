import React from "react"
import { TableCell, Typography,TableRow } from "@material-ui/core";

export default function EmptyRow({ info }) {
    return (
      <TableRow>
        <TableCell align="center"></TableCell>
        <TableCell component="th" scope="row">
          <Typography variant="caption" display="block" gutterBottom>
            {info}
          </Typography>
        </TableCell>
      </TableRow>
    )
  }