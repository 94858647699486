import React from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import { withSnackbar } from 'notistack';
import Typography from '@material-ui/core/Typography';
import { Box, Dialog, DialogTitle, DialogContent, DialogActions, DialogContentText } from '@material-ui/core';

class LoginForm extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      hash: '',
      enviando: false
    }

    this.onClickSubmit = this.onClickSubmit.bind(this)
    this.onTextChange = this.onTextChange.bind(this)
  }

  componentDidMount() {
    let accessKey = localStorage.getItem("_accessKey");
    console.log(accessKey);
    
    if (accessKey) {
      this.props.history.push('/home')
    } else {
      console.log("Usuario não logado")
    }
  }

  onClickSubmit = (e) => {
    e.preventDefault();
    let { hash } = this.state

    this.setState({ enviando: true })

    localStorage.setItem("_accessKey", hash)

    this.setState({ enviando: false })

    this.props.history.push('/home')

  }

  onTextChange = (e) => {
    // SetState com nome dinamico
    const { id, value } = e.target
    this.setState({ [id]: value })
  }


  render() {
    const { enviando } = this.state;

    return (

      <Container maxWidth="sm">
        <Box pt={10}>

        <Typography variant="h4" component="h1">
            Monitor de impressão - Parseint
        </Typography>
        <Typography variant="body1">
            Informe a chave de acesso
        </Typography>

          <Box mt={10}>
            <form onSubmit={(event) => { this.onClickSubmit(event) }}>
              <Card >
                <CardContent >

                  <TextField
                    variant="outlined"
                    required id="hash" value={this.state.hash} fullWidth
                    label="chave de acesso" onChange={(event) => { this.onTextChange(event) }}
                  />

                </CardContent>
                <CardActions>
                  <Button type="submit" size="large" color="primary" variant="contained" disabled={enviando}>
                    {enviando ? 'Acessando...' : 'Acessar'}
                  </Button>

                  <HelpDialog/>
                </CardActions>
              </Card>
            </form>
          </Box>
        </Box>
      </Container>
    )
  }
}


function HelpDialog() {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button  onClick={handleClickOpen}>
        Não sabe como obter a chave de acesso?
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Obter a chave de acesso"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">

          <p>Acesse o Sischef:</p>
          <ul> 
          <li>no Header principal, clique em 'Dados da empresa'</li>
          <li>Aba 'Integrações'</li>
          </ul>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
      
          <Button onClick={handleClose} color="primary" autoFocus>
            Entendi
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default withSnackbar(LoginForm);

